// @import '~antd/dist/antd.less';

html,
body,
#root,
#root > * {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

body {
  font-size: 14px;
  color: #333 !important;
}
*{
  padding: 0;
  margin: 0;
}
@primary-color: #1890ff;