.item{
  display: flex;
  margin: 10px 0;
  h3{
    width: 150px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: #001529;
    color: #fff;
  }
  .item-content{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #001529;
  }
}
@primary-color: #1890ff;