.contentWrapper{
  position: relative;
  top: 100px;
  max-width: 500px;
  width: 80vw;
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  .loginTitle {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-image: url('https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/login_banner.png');
    height: 157px;
    background-size: 100% 100%;
  }
  .loginContent {
    padding: 24px;
  }
}
@primary-color: #1890ff;